<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog" style="display: block;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form @submit="submit">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ item.id ? 'Edit' : 'Create' }} operator</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                              <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item mr-1" role="presentation">
                                  <button class="nav-link" :class="{active: tab === 'main'}" v-on:click="changeTab('main')" id="main-tab" data-bs-toggle="tab" data-bs-target="#main" type="button" role="tab" aria-controls="main" aria-selected="true">
                                    Main
                                  </button>
                                </li>
                                <li class="nav-item mr-1" role="presentation">
                                  <button class="nav-link" :class="{active: tab === 'currencies'}" v-on:click="changeTab('currencies')" id="currencies-tab" data-bs-toggle="tab" data-bs-target="#currencies" type="button" role="tab" aria-controls="currencies" aria-selected="true">
                                    Currencies & bets
                                  </button>
                                </li>
                                <li class="nav-item mr-1" role="presentation">
                                  <button class="nav-link" :class="{active: tab === 'slots'}" v-on:click="changeTab('slots')" id="slots-tab" data-bs-toggle="tab" data-bs-target="#slots" type="button" role="tab" aria-controls="slots" aria-selected="false">
                                    Slots
                                  </button>
                                </li>
                                <li class="nav-item mr-1" role="presentation">
                                  <button class="nav-link" :class="{active: tab === 'others'}" v-on:click="changeTab('others')" id="others-tab" data-bs-toggle="tab" data-bs-target="#others" type="button" role="tab" aria-controls="others" aria-selected="false">
                                    Other Settings
                                  </button>
                                </li>
                              </ul>
                              <div class="tab-content pt-3" id="myTabContent">
                                <div class="tab-pane fade" :class="{active: tab === 'main', show: tab === 'main'}" id="main" role="tabpanel" aria-labelledby="main-tab">
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Partner</label>
                                        <multiselect
                                          v-model="partner"
                                          :deselect-label="$t('Press enter to remove')"
                                          :multiple="false"
                                          :options="this.getPartners"
                                          :placeholder="$t('Select partner')"
                                          :select-label="$t('Press enter to select')"
                                          :selected-label="$t('Selected')"
                                          label="name"
                                          track-by="id"
                                        ></multiselect>
                                        <small class="text-danger" v-if="errors.partner">
                                          {{ errors.partner }}
                                        </small>
                                      </div>
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.isFullscreenAllowed" type="checkbox" class="form-check-input" id="isFullscreenAllowed">
                                        <label  class="form-check-label" for="isFullscreenAllowed">Allow fullscreen</label>
                                      </div>
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.isDebitRetryEnabled" type="checkbox" class="form-check-input" id="isDebitRetryEnabled">
                                        <label  class="form-check-label" for="isDebitRetryEnabled">Debit retry enabled</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Home button url</label>
                                        <input v-model="currentOperator.endpointHome"
                                               autocomplete="off"
                                               class="form-control"
                                               name="endpoint-home"
                                               placeholder="Enter url"
                                               type="text"
                                        >
                                      </div>
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.showHomeButton" type="checkbox" class="form-check-input" id="showHomeButton">
                                        <label  class="form-check-label" for="showHomeButton">Show button</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Name</label>
                                        <input v-model="currentOperator.name"
                                               autocomplete="off"
                                               class="form-control"
                                               name="name"
                                               placeholder="Enter operator name"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Endpoint debit</label>
                                        <input v-model="currentOperator.endpointDebit"
                                               autocomplete="off"
                                               class="form-control"
                                               name="endpoint-debit"
                                               placeholder="Enter debit endpoint"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Secret</label>
                                        <input v-model="currentOperator.secret"
                                               :disabled="this.mode !== 'create'"
                                               autocomplete="off"
                                               class="form-control"
                                               name="secret"
                                               placeholder="Enter secret"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Endpoint credit</label>
                                        <input v-model="currentOperator.endpointCredit"
                                               autocomplete="off"
                                               class="form-control"
                                               name="endpoint-credit"
                                               placeholder="Enter credit endpoint"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Token</label>
                                        <input v-model="currentOperator.token"
                                               :disabled="this.mode !== 'create'"
                                               autocomplete="off"
                                               class="form-control"
                                               name="token"
                                               placeholder="Enter token"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Endpoint rollback</label>
                                        <input v-model="currentOperator.endpointRollback"
                                               autocomplete="off"
                                               class="form-control"
                                               name="endpoint-rollback"
                                               placeholder="Enter rollback endpoint"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-6">
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Endpoint auth</label>
                                        <input v-model="currentOperator.endpointAuth"
                                               autocomplete="off"
                                               class="form-control"
                                               name="endpoint-auth"
                                               placeholder="Enter auth endpoint"
                                               type="text"
                                        >
                                      </div>
                                        <div class="form-group form-check">
                                            <input v-model="currentOperator.usePostMessageAuth" type="checkbox" class="form-check-input" id="usePostMessageAuth">
                                            <label  class="form-check-label" for="usePostMessageAuth">Use PostMessage for auth in lobby</label>
                                        </div>
                                        <div class="form-group">
                                          <label>Sitegroup</label>
                                            <multiselect
                                                v-model="sitegroup"
                                                :multiple="false"
                                                :allow-empty="false"
                                                :options="this.getSitegroup"
                                                :deselect-label="$t('Selected')"
                                                :placeholder="$t('Select sitegroup')"
                                                :select-label="$t('Press enter to select')"
                                                :selected-label="$t('Selected')"
                                                label="name"
                                                track-by="id"
                                            ></multiselect>
                                        </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>Callback timeout (seconds)</label>
                                        <input v-model="currentOperator.callbackTimeout"
                                               autocomplete="off"
                                               class="form-control"
                                               name="callbackTimeout"
                                               placeholder="2.0"
                                               type="text"
                                        >
                                      </div>
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.restoreSystemFreespins" type="checkbox" class="form-check-input" id="restoreSystemFreespins">
                                        <label  class="form-check-label" for="restoreSystemFreespins">Restore System Freespins on auth request</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">

                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="form-group">
                                        <label>Operator data <button type="button" class="btn btn-sm btn-default" @click="copyOperatorData()"><i class="fa fa-copy"></i></button></label>
                                        <textarea class="form-control" id="operatorData"
                                                  v-model="operatorData"
                                                  style="font-size: 80%; min-height: 150px"
                                                  readonly=""></textarea></div>
                                      </div>

                                  </div>
                                </div>
                                <div class="tab-pane fade" :class="{active: tab === 'currencies', show: tab === 'currencies'}" id="currencies" role="tabpanel" aria-labelledby="currencies-tab">
                                  <div class="row" v-for="(currency,index) in currencies" :key="currency.id">
                                    <div class="col-sm-2">
                                      <div class="form-group">
                                        <multiselect
                                          v-model="currencies[index].currency"
                                          :deselect-label="''"
                                          :multiple="false"
                                          :options="getCurrencies"
                                          :placeholder="$t('Currency')"
                                          :select-label="''"
                                          :selected-label="''"
                                          label="name"
                                          track-by="code"
                                          :options-limit="50"
                                        ></multiselect>
                                        <small class="text-danger" v-if="errors.currency[index]">
                                          {{ errors.currency[index] }}
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-sm-8">
                                      <div class="form-group">
                                        <input class="form-control" :class="{'border-danger': errors.bets[index]}" required v-model="currencies[index].bets">
                                        <small class="text-danger" v-if="errors.bets[index]">
                                          {{ errors.bets[index] }}
                                        </small>
                                      </div>
                                    </div>
                                    <div class="col-sm-2">
                                      <button class="btn btn-danger" type="button" v-on:click="removeOperatorCurrency(index)">
                                        <i class="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-sm-12">
                                      <button class="btn btn-default" v-on:click="addOperatorCurrency" type="button">
                                        <i class="fa fa-plus"></i> Add
                                      </button>
                                      <input ref="importOperatorCurrenciesFile" v-on:change="importOperatorCurrencies" name="file" type="file" style="display: none">
                                      <button class="btn btn-default ml-3" v-on:click="$refs.importOperatorCurrenciesFile.click()" type="button">
                                        <i class="fa fa-download"></i> Import
                                      </button>
                                      <button class="btn btn-default ml-3" v-on:click="exportOperatorCurrencies" type="button">
                                        <i class="fa fa-upload"></i> Export
                                      </button>
                                      <button class="btn btn-danger ml-3" v-on:click="removeAllOperatorCurrency" type="button">
                                        <i class="fa fa-trash"></i> Clear All
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div class="tab-pane fade" :class="{active: tab === 'slots', show: tab === 'slots'}" id="slots" role="tabpanel" aria-labelledby="slots-tab">
                                  <div class="text-nowrap ve-table">
                                    <div class="ve-table-container ve-table-border-around">
                                      <table class="ve-table-content ve-table-border-x">
                                      <tbody class="ve-table-body ve-table-row-hover ve-table-row-highlight">
                                      <tr class="ve-table-body-tr border-bottom">
                                          <td class="ve-table-body-td">0</td>
                                          <td class="ve-table-body-td">Lobby</td>
                                          <td class="ve-table-body-td" style="width: 100px">
                                              {{ isSlotDisabled(0) ? 'Disabled' : 'Active' }}
                                          </td>
                                          <td class="ve-table-body-td" style="width: 100px">
                                              <button type="button" class="btn btn-xs" :class="{'btn-success': !isSlotDisabled(0), 'btn-danger': isSlotDisabled(0)}" v-on:click="toggleSlot(0)">{{ isSlotDisabled(0) ? 'Enable' : 'Disable' }}</button>
                                          </td>
                                          <td></td>
                                      </tr>
                                      </tbody>
                                        <draggable tag="tbody" v-model="slots" class="ve-table-body ve-table-row-hover ve-table-row-highlight" handle=".handle">
                                        <tr v-for="game in slots" :key="game.id" class="ve-table-body-tr">
                                          <td class="ve-table-body-td">{{ game.id }}</td>
                                          <td class="ve-table-body-td">{{ game.name }}</td>
                                          <td class="ve-table-body-td" style="width: 100px">
                                            {{ isSlotDisabled(game.id) ? 'Disabled' : 'Active' }}
                                          </td>
                                          <td class="ve-table-body-td" style="width: 100px">
                                            <button type="button" class="btn btn-xs" :class="{'btn-success': !isSlotDisabled(game.id), 'btn-danger': isSlotDisabled(game.id)}" v-on:click="toggleSlot(game.id)">{{ isSlotDisabled(game.id) ? 'Enable' : 'Disable' }}</button>
                                          </td>
                                            <td>
                                                <i class="fa fa-align-justify handle"></i>
                                            </td>
                                        </tr>
                                        </draggable>
                                      </table>
                                    </div>
                                  </div>
                                </div>

                                <div class="tab-pane fade" :class="{active: tab === 'others', show: tab === 'others'}" id="others" role="tabpanel" aria-labelledby="others-tab">
                                  <div class="row mt-3">
                                    <div class="col-sm-12">
                                      <label>Client Settings</label>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.clientSettings.superFastSpins" type="checkbox" class="form-check-input" id="csSuperFastSpins">
                                        <label  class="form-check-label" for="csSuperFastSpins">Super fast spins</label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12">
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.hasSlowMode" type="checkbox" class="form-check-input" id="hasSlowMode">
                                        <label  class="form-check-label" for="hasSlowMode">Has a slow mode</label>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row mt-3">
                                    <div class="col-sm-12">
                                      <label>Win Limits</label>
                                      <div class="form-group form-check">
                                        <input v-model="currentOperator.isWinLimitEnabled" type="checkbox" class="form-check-input" id="isWinLimitEnabled">
                                        <label class="form-check-label" for="isWinLimitEnabled">Enabled</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="currentOperator.isWinLimitEnabled" class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <multiselect
                                          v-model="currentOperator.winLimitSettings.type"
                                          :deselect-label="''"
                                          :multiple="false"
                                          :options="getLimitSettingsTypes()"
                                          :placeholder="$t('Type')"
                                          :select-label="''"
                                          :selected-label="''"
                                          label="name"
                                          track-by="id"
                                        ></multiselect>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="isWinLimitType('type_bank')" class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <multiselect
                                          v-model="currentOperator.winLimitSettings.currency"
                                          :deselect-label="''"
                                          :multiple="false"
                                          :options="getOperatorCurrencies()"
                                          :placeholder="$t('Currency')"
                                          :select-label="''"
                                          :selected-label="''"
                                          label="name"
                                          track-by="code"
                                          :options-limit="50"
                                        ></multiselect>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <multiselect
                                          v-model="currentOperator.winLimitSettings.period"
                                          :deselect-label="''"
                                          :multiple="false"
                                          :options="getLimitPeriods()"
                                          :placeholder="$t('Period')"
                                          :select-label="''"
                                          :selected-label="''"
                                          label="name"
                                          track-by="id"
                                        ></multiselect>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <input v-model="currentOperator.winLimitSettings.bankSum"
                                               autocomplete="off"
                                               class="form-control"
                                               placeholder="Enter bank sum"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <input v-model="currentOperator.winLimitSettings.ggrRatio"
                                               autocomplete="off"
                                               class="form-control"
                                               placeholder="Enter GGR ratio"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                  </div>

                                  <div v-if="isWinLimitType('type_max_win')" class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <multiselect
                                          v-model="currentOperator.winLimitSettings.currency"
                                          :deselect-label="''"
                                          :multiple="false"
                                          :options="getOperatorCurrencies()"
                                          :placeholder="$t('Currency')"
                                          :select-label="''"
                                          :selected-label="''"
                                          label="name"
                                          track-by="code"
                                        ></multiselect>
                                      </div>
                                    </div>
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <input v-model="currentOperator.winLimitSettings.maxWin"
                                               autocomplete="off"
                                               class="form-control"
                                               placeholder="Enter MAX Win Sum"
                                               required
                                               type="text"
                                        >
                                      </div>
                                    </div>
                                  </div>

                                  <div class="row">
                                    <div class="col-sm-6">
                                      <div class="form-group">
                                        <label>RTP Preset</label>
                                        <multiselect
                                          v-model="currentOperator.reelsPreset"
                                          :deselect-label="''"
                                          :multiple="false"
                                          :options="getReelsPresets"
                                          :placeholder="$t('Default by slot')"
                                          :select-label="''"
                                          :selected-label="''"
                                          label="name"
                                          track-by="sysname"
                                        ></multiselect>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                        </div>



                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>

                        <button v-if="isDebug"
                                class="close"
                                title="dev-only. clear fields"
                                type="button"
                                @click="clearData"
                        ><i class="fas fa-trash"></i></button>

                        <button v-if="isDebug"
                                class="close"
                                title="dev-only. fill with random data"
                                type="button"
                                @click="randomData"
                        ><i class="fas fa-dice"></i></button>

                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</template>
<style>
.border-bottom td {
    border-bottom: 1px solid #eee !important;
}
</style>
<script>
import Multiselect from 'vue-multiselect';
import {generateRandomString} from '../helpers/makerUtils';
import {mapGetters} from 'vuex';
import {v4 as uuidv4} from 'uuid';
import draggable from 'vuedraggable'

const MODE_CREATE = 'create';

export default {
    props: [
        'item',
        'mode',
    ],
    components: {
        Multiselect,
        draggable
    },
    computed: {
        operatorData() {
            let data = [
              `Name: ${this.currentOperator.name}`,
              `Secret: ${this.currentOperator.secret}`,
              `Token: ${this.currentOperator.token}`,
              `Sitegroup: ${this.currentOperator.sitegroup}`,
              `Debit URL: ${this.currentOperator.endpointDebit ? this.currentOperator.endpointDebit : 'n/a'}`,
              `Credit URL: ${this.currentOperator.endpointCredit ? this.currentOperator.endpointCredit : 'n/a'}`,
              `Rollback URL: ${this.currentOperator.endpointRollback ? this.currentOperator.endpointRollback : 'n/a'}`,
              `Auth URL: ${this.currentOperator.endpointAuth ? this.currentOperator.endpointAuth : 'n/a'}`
            ];
            return data.join('\n');
        },
        isCreateMode() {
            return this.mode === MODE_CREATE;
        },
        isDebug() {
            return process.env.NODE_ENV === 'development';
        },
        ...mapGetters([
            'getPartners',
            'getSitegroup',
            'getCurrencies',
            'getReelsPresets',
        ]),
    },
    methods: {
        copyOperatorData() {
          (document.querySelector("#operatorData")).select();
          if (!navigator.clipboard){
            document.execCommand('copy');
          } else{
            navigator.clipboard.writeText(this.operatorData);
          }
        },
        toggleSlot(id) {
          const index = this.currentOperator.disabledSlotsIds.indexOf(id);

          if (index >= 0) {
            this.currentOperator.disabledSlotsIds.splice(index, 1);
          } else {
            this.currentOperator.disabledSlotsIds.push(id)
          }
        },
        isSlotDisabled(id) {
          if (!this.currentOperator.disabledSlotsIds) {
            return false;
          }

          return this.currentOperator.disabledSlotsIds.indexOf(id) >= 0;
        },
        changeTab(tab) {
            this.tab = tab
        },
        csvToArray(strData, strDelimiter = ",") {
          let objPattern = new RegExp(
            (
              "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
              "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
              "([^\"\\" + strDelimiter + "\\r\\n]*))"
            ),
            "gi"
          );

          let arrData = [[]];
          let arrMatches = objPattern.exec( strData );
          while (arrMatches) {
            let strMatchedDelimiter = arrMatches[ 1 ];
            if (
              strMatchedDelimiter.length &&
              (strMatchedDelimiter != strDelimiter)
            ){
              arrData.push( [] );
            }
            let strMatchedValue;

            if (arrMatches[ 2 ]){
              strMatchedValue = arrMatches[ 2 ].replace(
                new RegExp( "\"\"", "g" ),
                "\""
              );
            } else {
              strMatchedValue = arrMatches[ 3 ];
            }

            arrData[ arrData.length - 1 ].push( strMatchedValue );
            arrMatches = objPattern.exec( strData )
          }
          return( arrData );
        },
        jsonToCSV(objArray) {
          let array = objArray;
          let str = '';
          let line = '';

          for (let i = 0; i < array.length; i++) {
            line = '';

            for (let index in array[i]) {
              line += array[i][index] + ',';
            }

            line = line.slice(0, -1);
            if (i === array.length -1) {
              str += line;
            } else {
              str += line + '\r\n';
            }

          }
          return str;
        },
        exportOperatorCurrencies() {
          let csv = this.jsonToCSV(this.currencies.map(opcurrency => {
            let data = [
              opcurrency.currency ? opcurrency.currency.code : '',
            ];
            opcurrency.bets.split(',').forEach(bet => {
              data.push('"' + bet + '"');
            })
            return data;
          }));

          let downloadLink = document.createElement("a");
          let blob = new Blob(["\ufeff", csv]);
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = "bets_currencies.csv";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        },
        importOperatorCurrencies() {
          const reader = new FileReader();

          reader.onload = (e) => {
            const text = e.target.result;
            const data = this.csvToArray(text);
            for (let i in data) {
              console.log(data[i]);
              let items = [];
              for (let betIndex in data[i]) {
                items.push(data[i][betIndex])
              }
              let bets = items.splice(1);
              let code = items.join('');
              this.currencies.push({
                id: null,
                currency: this.getCurrency(code),
                bets: bets.join(',')
              });
            }
          };

          reader.readAsText(this.$refs.importOperatorCurrenciesFile.files[0]);
        },
        init() {
            if (typeof this.currentOperator.winLimitSettings.type === 'string') {
                this.currentOperator.winLimitSettings.type = this.getLimitSettingsTypes().find((type) => type.id === this.currentOperator.winLimitSettings.type);
            }
            if (typeof this.currentOperator.winLimitSettings.currency === 'string') {
                this.currentOperator.winLimitSettings.currency = this.getCurrency(this.currentOperator.winLimitSettings.currency);
            }

            if (typeof this.currentOperator.winLimitSettings.period === 'string') {
                this.currentOperator.winLimitSettings.period = this.getLimitPeriods().find((period) => period.id === this.currentOperator.winLimitSettings.period);
            }

            if (typeof this.currentOperator.reelsPreset === 'string') {
                this.currentOperator.reelsPreset = this.getReelsPresets.find((preset) => preset.sysname === this.currentOperator.reelsPreset);
            }
        },
        isWinLimitType(type) {
            if (!this.currentOperator.isWinLimitEnabled) {
                return false;
            }
            if (!this.currentOperator.winLimitSettings.type) {
                return false;
            }
            return this.currentOperator.winLimitSettings.type.id === type;
        },
        getLimitPeriods() {
            return [
                {id: 'day', name: 'Day'},
                {id: 'week', name: 'Week'},
                {id: 'month', name: 'Month'},
            ]
        },
        getLimitSettingsTypes() {
            return [
                {id: 'type_max_win', name: 'Player max win limit'},
                {id: 'type_bank', name: 'Bank limit'},
            ]
        },
        getOperatorCurrencies() {
            let currencies = [];
            this.currencies.forEach(function (oc) {
                currencies.push(oc.currency);
            });

            return currencies;
        },
        removeAllOperatorCurrency() {
          this.currencies = [];
        },
        removeOperatorCurrency(index) {
            this.currencies.splice(index, 1)
        },
        addOperatorCurrency() {
            this.currencies.push({
                id: null,
                currency: null,
                bets: '',
            });
        },
        getCurrency(code) {
            return this.getCurrencies.find(currency => currency.code === code)
        },
        getPartner(id) {
            return this.getPartners.find(partner => partner.id === id)
        },
        randomData() {
            this.currentOperator.name = generateRandomString(10);
            this.currentOperator.secret = uuidv4();
            this.currentOperator.token = uuidv4();

            this.currentOperator.endpointHome = 'https://' + generateRandomString(8);
            this.currentOperator.endpointDebit = 'https://' + generateRandomString(8);
            this.currentOperator.endpointCredit = 'https://' + generateRandomString(8);
            this.currentOperator.endpointRollback = 'https://' + generateRandomString(8);
            this.currentOperator.endpointAuth = 'https://' + generateRandomString(8);
        },
        clearData() {
            this.currentOperator.name = '';
            this.currentOperator.secret = '';
            this.currentOperator.token = '';

            this.currentOperator.endpointHome = '';
            this.currentOperator.endpointDebit = '';
            this.currentOperator.endpointCredit = '';
            this.currentOperator.endpointRollback = '';
            this.currentOperator.endpointAuth = '';
        },
        valid() {
            let isValid = true;
            if (!this.partner || !this.partner.id) {
                isValid = false;
                this.errors.partner = 'Please select partner';
            } else {
                this.errors.partner = null;
            }


            let currenciesRows = {}
            for (let i in this.currencies) {
                if (!this.currencies[i].currency) {
                    this.errors.currency[i] = 'Please select currency';
                    isValid = false;
                } else {
                    this.errors.currency[i] = null;
                    if (!Object.prototype.hasOwnProperty.call(currenciesRows, this.currencies[i].currency.code)) {
                        currenciesRows[this.currencies[i].currency.code] = [];
                    }
                    currenciesRows[this.currencies[i].currency.code].push(i);
                }
                if (/^(\s*-?\d+(\.\d+)?)(\s*,\s*-?\d+(\.\d+)?)*$/.test(this.currencies[i].bets) === false) {
                    isValid = false;
                    this.errors.bets[i] = 'Invalid bets value, should be comma separated digits (ex: 0.1, 1, 10, 5)';
                } else {
                    this.errors.bets[i] = null;
                }

                for (let currency in currenciesRows) {
                    if (currenciesRows[currency].length > 1) {
                        isValid = false;
                        for (let index in currenciesRows[currency]) {
                            this.errors.bets[currenciesRows[currency][index]] = 'Duplicate currency ' + currency;
                        }
                    }
                }
            }

            if (!isValid) {
                this.$forceUpdate();
            }

            return isValid;
        },
        submit(e) {
            e.preventDefault();
            if (!this.valid()) {
                return;
            }

            let url = null;
            let method = null;

            if (this.isCreateMode) {
                method = 'POST';
                url = '/operators';
            } else {
                method = 'PUT';
                url = '/operators/' + this.currentOperator.id;
            }

            let winLimitSettings = {
                ...this.currentOperator.winLimitSettings,
                type: this.currentOperator.winLimitSettings.type ? this.currentOperator.winLimitSettings.type.id : null,
                currency: this.currentOperator.winLimitSettings.currency ? this.currentOperator.winLimitSettings.currency.code : null,
                period: this.currentOperator.winLimitSettings.period ? this.currentOperator.winLimitSettings.period.id : null
            }

            this.$http({
                url: url,
                data: {
                    partnerId: this.partner.id,
                    name: this.currentOperator.name,
                    secret: this.currentOperator.secret,
                    token: this.currentOperator.token,
                    endpointHome: this.currentOperator.endpointHome,
                    endpointDebit: this.currentOperator.endpointDebit,
                    endpointCredit: this.currentOperator.endpointCredit,
                    endpointRollback: this.currentOperator.endpointRollback,
                    endpointAuth: this.currentOperator.endpointAuth,
                    callbackTimeout: this.currentOperator.callbackTimeout ? this.currentOperator.callbackTimeout : null,
                    showHomeButton: this.currentOperator.showHomeButton,
                    hasSlowMode: this.currentOperator.hasSlowMode,
                    isDebitRetryEnabled: this.currentOperator.isDebitRetryEnabled,
                    isWinLimitEnabled: this.currentOperator.isWinLimitEnabled,
                    isFullscreenAllowed: this.currentOperator.isFullscreenAllowed,
                    restoreSystemFreespins: this.currentOperator.restoreSystemFreespins,
                    usePostMessageAuth: this.currentOperator.usePostMessageAuth,
                    sitegroupId: this.sitegroup ? this.sitegroup.id : 1,
                    reelsPreset: this.currentOperator.reelsPreset ? this.currentOperator.reelsPreset.sysname : null,
                    winLimitSettings: winLimitSettings,
                    currencies: this.currencies.map(function (oc) {
                        return {
                            id: oc.id,
                            bets: oc.bets.split(','),
                            currency: oc.currency.code
                        }
                    }),
                    clientSettings: {
                        superFastSpins: this.currentOperator.clientSettings.superFastSpins
                    },
                    disabledSlotsIds: this.currentOperator.disabledSlotsIds,
                    slotsOrder: this.slots.map((slot) => parseInt(slot.id))
                },
                method: method,
            }).then(() => {
                this.$emit('close');
                this.$emit('finish');
                this.$store.dispatch('loadOperators');
                this.$store.dispatch('loadGames');
            }).catch((err) => {
                console.error(err);
                if (err.response.status == 422) {
                    let errors = err.response.data;
                    errors.forEach((error) => {
                        if (!error.field) {
                            this.$toastr.e(error.message);
                        } else {
                            this.$toastr.e(error.field + ': ' + error.message);
                        }
                    })
                } else {
                    this.$toastr.e('Server error ' + err.response.status);
                }
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
        this.partner = this.getPartner(this.item.partnerId);
        this.sitegroup = this.getSitegroup.find(sitegroup => sitegroup.id === this.item.sitegroupId)

        let self = this;
        this.item.currencies && this.item.currencies.forEach(function (operatorCurrency) {
            self.currencies.push({
                id: operatorCurrency.id,
                currency: self.getCurrency(operatorCurrency.currency),
                bets: operatorCurrency.bets.join(', '),
            })
        });


        this.init();
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
    data() {
        if (!this.item.id) {
            this.item.secret = uuidv4();
            this.item.token = uuidv4();
        }


        let winLimitSettings = this.item.winLimitSettings;
        if (!winLimitSettings) {
            winLimitSettings = {};
        }

        let clientSettings = this.item.clientSettings;
        if (!clientSettings) {
            clientSettings = {
                superFastSpins: false
            }
        }

        let order = {};
        this.item.slotsOrder.forEach((a, i) => {order[a] = i})

        let slots = this.$store.getters.getGames.slice().sort((a, b) => {
            let aIndex = order[parseInt(a.id)] ?? -1;
            let bIndex = order[parseInt(b.id)] ?? -1;

            if (aIndex === -1 && bIndex === -1) {
                return b.id - a.id;
            }

            if (aIndex !== -1 && bIndex === -1) {
                return -1;
            }

            if (aIndex === -1 && bIndex !== -1) {
                return 1;
            }

            return aIndex - bIndex;
        });

        return {
            slots: slots,
            currentOperator: {
                ...this.item,
                winLimitSettings: winLimitSettings,
                clientSettings: clientSettings,
                disabledSlotsIds: this.item.disabledSlotsIds
            },
            partner: null,
            sitegroup: null,
            currencies: [],
            tab: 'main',
            errors: {
                partner: null,
                bets: [],
                currency: []
            }
        };
    },
};
</script>
